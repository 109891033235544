<template>
    <div class="property-block display-card p-0">
        <div class="propertyImage">
            <MiniImgSlider :images="itemImages"/>
            <span v-if="item.property.purpose=='rent'" class="propertyPrice">{{ item.property.price }}/{{ item.property.price_for }} </span>
            <span v-else class="propertyPrice">{{ item.property.price }}</span>
            <div class="quick-view" @click="quickView(item.property.slug)" v-b-tooltip.hover title="Quick View">
                <span class="icon-quick-view"></span>
            </div>
        </div>
        <div class="property-content" >
            <h3><router-link :to="{ name: 'PropertyDetail', params: {slug:item.property.slug, id:item.id } }">{{ item.property.title }}</router-link></h3>
            <span class="listLocation"> <span class="icon-location"></span> {{ item.property.address }}</span>
            <ul>
                <li v-if="item.property.category!=null">{{ item.property.category.title }}</li>
                <li v-if="item.property.type!=null">{{ item.property.type.title }}</li>
                <li>{{ item.property.approx_area }}</li>
            </ul>
            <hr>
            <div class="propertyCTA">
                <!-- <span v-if="item.property.kj_select== 1" class="propertyType kj-select">KJ Select</span> -->
                <span class="propertyType">For {{ item.property.purpose }}</span>
                <div>
                    <p v-if="item.ads_type"> <span class="icon-crown"></span> {{ item.ads_type.title }}</p>
                    <p v-else><router-link :to="{ name: 'PropertyDetail', params: {slug:item.property.slug, id:item.id } }"><span> Details </span></router-link></p>
                    <template v-if="isAuthenticated">
                        <span v-if="favouriteProperty" class="icon-wishlist-selected" v-b-tooltip.hover title="Added to Favorite"></span>
                        <span v-else class="icon-wishlist" v-b-tooltip.hover title="Add to Favorite" @click="addToWishlist(item.id)"></span>
                    </template>
                    <template v-else>
                        <span class="icon-wishlist" v-b-tooltip.hover title="Add to Favorite" @click="loginAction('login-btn')" v-b-modal.authModal ></span>
                    </template>
                </div>
                
            </div>
        </div>
    </div>
</template>
<style>
    .quick-view{
        display: none;
    }
    .propertyImage:hover .quick-view{
        float: right;
        position: absolute;
        right: 8px;
        bottom: 15px;
        border: 1px solid #fff;
        padding: 5px;
        border-radius: 50px;
        cursor: pointer;
        display: block;
        background: #fff;
    }
    .quick-view .icon-quick-view{
        width: 20px;
        height: 20px;
        display: block;
        background: var(--brand-color);
    }
</style>
<script>
import axios from "axios";
import api from "@/services/api";
import store from "@/store";
import MiniImgSlider from "./MiniImgSlider";

export default {
    name: "ItemList",
    components:{MiniImgSlider},
    props:{
        item:{
            required: true,
        }
    },
    data(){
        return {
            itemImages: this.item.property.property_images,
            isAuthenticated: store.getters.authenticated,
            favouriteProperty: this.item.property.favourite,
        }
    },
    methods:{
        addToWishlist(property_id){
            let app = this;
            axios.post(api.getUrl('/user/property/wishlist/store'),{user_id:store.getters.user.id,property_id:property_id}).then((response) => {
                if(response.data.success == true){
                    app.$root.$emit('wishlist-count',response.data.data.length);
                    store.commit("setUserWishlist", response.data.data.length);
                    app.favouriteProperty = true;
                    app.$toast.success(response.data.message,{position: "top-center",timeout: 2000});
                }else{
                    app.$toast.error(response.data.message,{position: "top-center",timeout: 1500});
                }
            });
        },
        loginAction(source) {
            store.commit("setLoginSource", source);
        },
        quickView(data){
            let app = this;
            app.$root.$emit("quickview-status", true);
            app.$bvModal.show('DetailQuickView');
            app.$root.$emit("property-data", data);
           
        }
    },
    watch: {
        item(value) {
            let app = this;
            app.itemImages =  value.property.property_images;
            app.favouriteProperty = value.property.favourite;
        },
    },

}
</script>